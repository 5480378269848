document.addEventListener("DOMContentLoaded", function() {
  const q = params.q ? params.q.replace(" ", "+") : "";
  let start = params.start ? params.start : 1;

  const resultsDiv = document.getElementById("searchResults");
  if (!resultsDiv) return;

  const key = resultsDiv.getAttribute("data-key");

  fetch(`https://icctemzx4l.execute-api.us-west-2.amazonaws.com/default/GoogleCSEGateway?q=${q}&start=${start}&s=${key}`)
  .then(function (response) {
    return response.text();
  })
  .then(function(response) {
    resultsDiv.innerHTML = response;
  })
  .catch(function (error) {
    console.log(error);
  });

});
