document.querySelectorAll("dialog").forEach(dialog => {
  // Handles "faux" close button using empty anchor to clear the `target:`
  const closeButton = dialog.querySelector(`a[href="#close"`);
  if (closeButton) {
    closeButton.addEventListener("click", function(event) {
      dialog.close();
    });
  }
});

/**
 * Opens faux modal using CSS `target:` to actual modal.
 */
 function openModalByHash() {
  const hash = window.location.hash;
  if (hash) {
    const modal = document.querySelector(`dialog[id="${hash.substring(1)}"]`);
    if (modal) {
      !modal.open && modal.show();
    }
  }
}

window.addEventListener("hashchange", openModalByHash, false);
openModalByHash();
