import AOS from "../common/aos.js";

// make aos-delay-container increment by set amount
document.querySelectorAll("[data-aos-delay-container]").forEach(container => {
  let increment = parseInt(container.getAttribute("data-aos-delay-container"));
  container.querySelectorAll("[data-aos]").forEach( (el,idx) => {
    let delay = idx * increment;
    el.setAttribute("data-aos-delay", delay);
  });
});

document.querySelectorAll("[data-aos-switch").forEach(container => {
  container.children.forEach((el, idx) => {
    if (idx % 2 == 0) {
      el.setAttribute("data-aos","fade-right");
    } else {
      el.setAttribute("data-aos", "fade-left");
    }
  });
});

document.querySelectorAll(".cta-section a.button").forEach(button => {
  button.setAttribute("data-aos","zoom-in");
});

document.querySelectorAll("[data-aos='count-up']").forEach(el => {

  if (!el.querySelector("span.number")) return;

  let span = el.querySelector("span.number");
  let text = span.textContent;
  let percent = text.includes("%") ? "%" : "";
  let num = parseFloat(span.textContent.replace(/,/gi, ''));
  let duration = el.getAttribute("data-aos-duration") ? parseInt(el.getAttribute("data-aos-duration")) : 1000;
  let times = el.getAttribute("data-aos-times") ? parseInt(el.getAttribute("data-aos-times")) : 10;
  let inc = num / times;
  let step = duration / times;

  // Even though a default will be used for duration, it really needs to be
  // set in markup so that the blur effect will remain until it's finished.

  span.textContent = 0 + percent;

  el.addEventListener("aos:in", () => {
    let x = 0;
    el.timer = setInterval(() => {
      x++;
      let total = x * inc;

      if (num > times) {
        total = parseInt(total);
      }
      span.textContent = total.toLocaleString() + percent;

      if (x === times) {
        clearInterval(el.timer);
        span.textContent = text;
      }
    }, step);
  })
});

AOS.init();
