let map, infoWindow, myPosition, bounds, activeMarker;
let markers = [];
let notFoundString = '<p class="text-ms1 font-semibold text-center">No Results.<br/>Use map to find a location</p>';

const options = {
  zoom: 4,
  lat: 39.5,
  long: -98.35,
  disableDefaultUI: true,
  zoomControl: true,
  minZoom: 4,
  restriction: {
    latLngBounds: {
      north: 60,
      south: 24,
      east: -65,
      west: -126
    }
  }
};

const icons = {
  default: {
    url: "/images/libertytire.com/marker.svg",
    labelOrigin: {
      x: 24,
      y: 22
    }
  },
  active: {
    url: "/images/libertytire.com/active-marker.svg",
    labelOrigin: {
      x: 24,
      y: 22
    }
  },
  mine: {
    url: "/images/libertytire.com/my-location-blue.svg",
    labelOrigin: {
      x: 23,
      y: 22
    }
  }
}

function initMap(elId) {

  const styledMapType = new google.maps.StyledMapType(
    [
      { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ color: "#c9b2a6" }],
      },
      // {
      //   featureType: "administrative.locality",
      //   stylers: [{ visibility: "off" }],
      // },
      {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [{ color: "#dcd2be" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#000000" }],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#EBEBEB" }],
      },
      {
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#C2D1CE" }],
      },
      {
        featureType: "water",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
      },
    ],
    { name: "Styled Map" }
  );

  infoWindow = new google.maps.InfoWindow();

  map = new google.maps.Map(document.getElementById("myMap"), options);
  bounds = new google.maps.LatLngBounds();

  map.mapTypes.set("styled_map", styledMapType);
  map.setMapTypeId("styled_map");

  if (typeof myLocations === "object" && myLocations.length > 0) {
    let id = 0;
    myLocations.forEach( (loc) => {
      if (!loc.latitude || !loc.longitude) return;
      addMarker(loc, id++, bounds);
    });

    map.fitBounds(bounds);
  }

  if (params.zip) {
    document.querySelector(".location-search").value = params.zip;
    setTimeout(() => {
      submitForm();
      document.getElementById("Locations-Map").scrollIntoView(true);
    }, 400);
  }

}

function addMarker(loc, id, bounds) {
  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(loc.latitude, loc.longitude),
    map,
    title: loc.name,
    id: id,
    icon: icons.default
  });

  marker.locationInfo = {
    name: loc.name,
    address: loc.address,
    city: loc.city,
    state: loc.state,
    postal: loc.postalCode,
    phone: loc.phone
  };

  const infoString = `
  <div class="font-primary text-ms-1">
    <p class="font-bold text-ms0 mt-0">${loc.name}</p>
    <p class="mt-ms-1">${loc.address}<br />
      ${loc.city}, ${loc.state} ${loc.postalCode}</p>
    <p class="icon-phone">
      <span class="sr-only">Phone:</span>
      <a href="tel:${loc.phone}">${loc.phone}</a>
    </p>
  </div>
`;

  marker.addListener("click", () => {
    infoWindow.close();
    infoWindow.setContent(infoString);
    infoWindow.open({
      anchor: marker,
      map: map,
      shouldFocus: true
    });
    setActiveMarker(marker);
    setInspectorBox(marker);
  });

  bounds.extend(marker.position);
  markers.push(marker);
}

function locateUser() {
  if (navigator.geolocation) {
    // Try HTML5 geolocation.
    navigator.geolocation.getCurrentPosition(
      function positionFound(position) {
        var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        setZip(pos);
        setUserLocation(pos, true);
      },
      function positionError(error) {
        console.log(error);
      });
  } else {
    // Browser doesn't support Geolocation
    console.log("Browser does ont support Geolocation");
  }
}

function findNearest(pos) {
  markers.forEach(function setMarkerDistance(marker) {
    var markerDistance = google.maps.geometry.spherical.computeDistanceBetween(pos, marker.getPosition());
    marker.locationInfo.distance = getMiles(markerDistance);
  });
  markers.sort(function sortMarkers(a, b) {
    return a.locationInfo.distance - b.locationInfo.distance;
  });
  populateNearest();
}

function populateNearest(start) {
  let nearbyMarkers, end;
  let html = "";
  let results = 5;
  let mapInspector = document.getElementById("mapInspector");

  infoWindow.close();

  if (typeof start === "undefined") {
    start = 0;
  } else {
    start = parseInt(start);
  }

  if (start === 0) {
    bounds = new google.maps.LatLngBounds();
    markers.forEach(marker => {
      marker.setLabel(null);
    })
  }

  end = (start + results);

  if (end > markers.length) {
    end = markers.length;
  }

  nearbyMarkers = markers.slice(start, end);

  nearbyMarkers.forEach(function addVisibleMarkersToMap(marker, index) {
    marker.setLabel({
      text: String(start + index + 1),
      color: "white",
      fontSize: "18px",
      fontWeight: "bold"
    });
    bounds.extend(marker.position);
  });

  map.fitBounds(bounds);
  document.querySelector(".no-results")?.classList.remove("no-results");

  if (nearbyMarkers.length > 0) {
    nearbyMarkers.forEach(function renderNearbyMarkerList(marker, index) {
      let info = marker.locationInfo;
      let displayNumber = start + index + 1;

      html += `
        <div class="inspector-box" data-id="${marker.id}">
        <div class="number">
          <span>${displayNumber}</span>
        </div>
        <div class="text">
          <p class="font-bold text-ms0">${info.name}</p>
          <p>${info.address}<br />
            ${info.city}, ${info.state} ${info.postal}<br/>
            <a href="${getDirectionsLink(info)}" target="_blank">Get Directions</a>
          </p>
          <p>
            <a href="tel:${info.phone}" class="before:icon-phone before:mr-ms-2 before:no-underline">${info.phone}</a>
          </p>
        </div>
        <div class="miles">${info.distance.toFixed(1)} miles</div>
      </div>
      `;
    });

    if (end < markers.length) {
      html += `
      <div class="m-ms1 text-center">
        <button id="loadMore" class="button" data-start="${end}">Load More</button>
      </div>
      `;
    }
  } else {
    html = notFound;
  }

  if (start === 0) {
    mapInspector.innerHTML = html;
  } else {
    mapInspector.innerHTML = mapInspector.innerHTML + html;
    mapInspector.scrollBy({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
  }

}

function setUserLocation(pos, isGeo) {
  map.setCenter(pos);

  if (myPosition) {
    myPosition.setPosition(pos);
    myPosition.setMap(map);
  } else {
    myPosition = new google.maps.Marker({
      position: pos,
      map: map,
      id: "my-position",
      icon: icons.mine,
      zIndex: 0
    });
  }

  map.setZoom(12);
  findNearest(pos);
}

function setZip(pos) {
  var geocoder = new google.maps.Geocoder();

  geocoder.geocode({ "location": pos }, function geocodePosition(results, status) {
    if (status === "OK") {
      if (results[0]) {
        results[0].address_components.forEach(function eachAddressComponent(comp) {
          if (comp.types[0] === "postal_code") {
            document.querySelector(".location-search").value = comp.long_name;
          }
        });
      } else {
        console.log("Could not geocode coordinates.");
      }
    } else {
      console.log("Geocoder failed due to: " + status);
    }
  });
}

function getMiles(meters) {
  return Number(meters) * 0.000621371192;
}

function getDirectionsLink(li) {
  var url = "https://www.google.com/maps/dir/?api=1&destination=";

  url += encodeURIComponent(li.address + ", " + li.city + ", " + li.state + " " + li.zip);
  if (myPosition) {
    url += "&origin=";
    url += encodeURIComponent(myPosition.getPosition().lat() + "," + myPosition.getPosition().lng());
  }
  return url;
}

function submitForm(e) {
  var geocoder = new google.maps.Geocoder();
  var address = document.querySelector(".location-search").value;

  if (typeof e === "object") {
    e.preventDefault();
  }

  if (infoWindow) {
    infoWindow.close();
  }

  if (address !== "") {
    geocoder.geocode({ "address": address }, function gcc(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        setUserLocation(results[0].geometry.location, false);
        document.querySelector(".error-msg").textContent = "";
      } else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
        document.querySelector(".error-msg").textContent = "No results found. Please try again.";
      } else {
        document.querySelector(".error-msg").textContent = "An error has occurred. Please try a different value.";
        console.log(`Geocode Status: ${status}`);
      }
    });
  } else {
    map.setZoom(options.zoom);
    map.setCenter(new google.maps.LatLng(options.lat, options.long));
  }
}

function toggleMapDisplay(event) {
  event.preventDefault();
  if (event.target.tagName !== "BUTTON") return;

  let button = event.target;

  if (!button.classList.contains("active")) {
    let sibling = button.nextElementSibling ? button.nextElementSibling : button.previousElementSibling;
    button.classList.add("active");
    sibling.classList.remove("active");

    let el = document.getElementById(event.target.getAttribute("rel"));
    sibling = el.nextElementSibling ? el.nextElementSibling : el.previousElementSibling;
    el.classList.remove("hidden");
    sibling.classList.add("hidden");
  }
}

function setActiveMarker(marker) {
  if (activeMarker && activeMarker !== marker) {
    activeMarker.setIcon(icons.default);
    activeMarker.setZIndex(null);
  }

  activeMarker = marker;

  activeMarker.setIcon(icons.active);
  activeMarker.setZIndex(10);
}

function setInspectorBox(marker) {
  let box = document.querySelector(`.inspector-box[data-id="${marker.id}"]`);

  if (!box) return;

  if (!box.classList.contains("active")) {
    document.querySelectorAll(".inspector-box.active").forEach((box) => box.classList.remove("active"));
    box.classList.add("active");
    document.querySelector("#mapInspector").scrollTo({
      top: box.offsetTop,
      behavior: "smooth"
    });
  }
}

if (document.querySelector("#myMap")) {

  document.querySelector(".map-display")?.addEventListener("click", toggleMapDisplay);

  document.querySelector(".search-map")?.addEventListener("submit", submitForm);

  document.querySelector(".locate-me")?.addEventListener("click", locateUser);

  document.querySelector("#mapInspector")?.addEventListener("click", (event) => {
    let el = event.target;

    if (el.tagName === "A") return;

    if (el.id === "loadMore") {
      let start = el.getAttribute("data-start");
      el.remove();
      populateNearest(start);
      return;
    }

    if (!el.matches('.inspector-box')) {
      el = el.closest(".inspector-box");
    }

    if (!el || el.classList.contains("active")) return;

    event.preventDefault();

    let markerId = parseInt(el.getAttribute("data-id"));

    let chosenMarker = markers.find(obj => {return obj.id === markerId});

    map.panTo(chosenMarker.position);
    map.setZoom(8);

    google.maps.event.trigger(chosenMarker, 'click', {
      latLng: new google.maps.LatLng(0, 0)
    });
  });

  window.initMap = initMap;

  const script = document.createElement("script");
  script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAfJgZaaVjUSDMhANeCCnemDf5bWvhEAlI&libraries=geometry&callback=initMap";
  script.async = true;

  // Append the 'script' element to 'head'
  document.head.appendChild(script);
}
