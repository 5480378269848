import "../../../../../node_modules/scroll-timeline-polyfill/dist/scroll-timeline.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const parallaxContainer = $('.parallax');

if (parallaxContainer) {
  $('.parallax img').animate({
    transform: ['translateY(-30%)', 'translateY(30%)']}, {
    timeline: new ViewTimeline({ subject: parallaxContainer }),
    timeRange: 'cover 0% 100%'
  });
}