const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let shareTool = $(".article-share-tool");

if (shareTool) {
  let url = document.location.href;
  let title = document.title;

  $(".article-share-tool .before\\:icon-email")?.setAttribute("href",`mailto:?subject=Article | Liberty Tire&body=${encodeURIComponent(url)}`);
  $(".article-share-tool .before\\:icon-facebook-square")?.setAttribute("href",`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}`);
  $(".article-share-tool .before\\:icon-twitter")?.setAttribute("href",`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`);
  $(".article-share-tool .before\\:icon-linkedin")?.setAttribute("href",`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`);
}
