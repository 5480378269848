import A11YSlider from "../../../../../node_modules/a11y-slider/dist/a11y-slider.esm.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let sliders = $$(".carousel .a11y-slider");

function throttle(func, wait = 100) {
  let timer = null;
  return function(...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}

if (sliders.length > 0) {

  sliders.forEach(slider => {
    if (slider.children.length > 1 ) {
      let sliderInstance;
      let previous = $(".carousel__controls .prev", slider.parentNode.parentNode);
      let next = $(".carousel__controls .next", slider.parentNode.parentNode);
      let countDisplay = $(".carousel__counter .current", slider.parentNode.parentNode);
      let totalDisplay = $(".carousel__counter .total", slider.parentNode.parentNode);

      slider.addEventListener('init', function(e) {
        let sliderInstance = e.detail.a11ySlider;
        let slider = e.detail.a11ySlider.slider;
        let figcaption = $("figcaption",e.detail.a11ySlider.activeSlide);
        let carouselControls = $(".carousel__controls", slider.parentNode.parentNode);
        let skipButton = $("button.a11y-slider-sr-only", slider.parentNode.parentNode);
        let visibleSlides = e.detail.a11ySlider.visibleSlides;
        if (visibleSlides.length > 0) {
          visibleSlides[visibleSlides.length - 1].classList.add("mask");
          skipButton.insertAdjacentElement("afterend", carouselControls);
        }

        if (figcaption) {
          let captionContainer = document.createElement("div");
          let caption = figcaption.textContent;
          captionContainer.innerHTML = `<p>${caption}</p>`;
          captionContainer.classList.add("carousel__caption");
          slider.parentNode.insertAdjacentElement('afterend', captionContainer);
        }

        setTimeout(function(){
          sliderInstance.scrollToSlide(0);
        },200);
      })

      slider.addEventListener('beforeChange', function(e) {
        let carouselCaption = $(".carousel__caption", slider.parentNode.parentNode);
        let maskedSlides = $$(".mask", slider);

        maskedSlides.forEach(function(mask) {
          mask.classList.remove("mask");
        })

        if(e.detail.currentSlide !== e.detail.nextSlide && carouselCaption) {
          carouselCaption.classList.add("hide-caption");
        }
      })

      slider.addEventListener('afterChange', function(e) {
        let sliderInstance = e.detail.a11ySlider;
        let slideCount = sliderInstance.slides.length;
        let currentSlide = sliderInstance.activeSlide;
        let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);
        let slideShowCount = sliderInstance.options.slidesToShow;
        let figcaption = $("figcaption",e.detail.a11ySlider.activeSlide);
        let potentialPage = Math.floor((currentIndex)/slideShowCount) + 1;
        let visibleSlides = e.detail.a11ySlider.visibleSlides;
        if (visibleSlides.length > 0) {
          visibleSlides[visibleSlides.length - 1].classList.add("mask");
        }

        if (slideCount - (currentIndex+1) < slideShowCount && currentIndex % slideShowCount !== 0) {
          potentialPage = potentialPage + 1;
        }

        countDisplay.textContent = potentialPage;

        if (figcaption) {
          let caption = figcaption.textContent;
          let carouselCaption = $(".carousel__caption", slider.parentNode.parentNode);
          carouselCaption.innerHTML = `<p>${caption}</p>`;
          carouselCaption.classList.remove("hide-caption");
        }
      })

      sliderInstance = new A11YSlider(slider, {
        slidesToShow: 2,
        dots: false,
        prevArrow: $(".carousel__arrows .prev", slider.parentNode.parentNode),
        nextArrow: $(".carousel__arrows .next", slider.parentNode.parentNode)
      })

      let carouselControls = $(".carousel__controls", sliderInstance.slider.parentNode.parentNode);
      let slideCount = sliderInstance.slides.length;
      let changingShowCount = sliderInstance.options.slidesToShow;
      let currentSlide = sliderInstance.activeSlide;
      let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);
      if (slideCount <= changingShowCount) {
        carouselControls.classList.add("hidden");
      }

      previous.setAttribute("disabled","disabled");
      totalDisplay.textContent = Math.ceil(slideCount/changingShowCount);
      countDisplay.textContent = Math.ceil((currentIndex+1)/changingShowCount);

      const throttled = throttle(function() {
        if (slideCount <= changingShowCount) {
          carouselControls.classList.add("hidden");
        } else {
          carouselControls.classList.remove("hidden");
        }
        if (changingShowCount !== sliderInstance.options.slidesToShow) {
          changingShowCount = sliderInstance.options.slidesToShow;
          totalDisplay.textContent = Math.ceil(slideCount/changingShowCount);
        }
      }, 500);
      window.addEventListener('resize', throttled);

      next.addEventListener("click", function() {
        let slideCount = sliderInstance.slides.length;
        let slideShowCount = sliderInstance.options.slidesToShow;
        let currentSlide = sliderInstance.activeSlide;
        let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);

        if (currentIndex + slideShowCount < slideCount) {
          sliderInstance.scrollToSlide(currentIndex + slideShowCount);
        }
      }, false);

      previous.addEventListener("click", function() {
        let slideShowCount = sliderInstance.options.slidesToShow;
        let currentSlide = sliderInstance.activeSlide;
        let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);
        let pushAmount = slideShowCount;
        let potentialOffset = currentIndex % slideShowCount;
        if (potentialOffset !== 0) {
          pushAmount = potentialOffset;
        }

        if (currentIndex - pushAmount >= 0) {
          sliderInstance.scrollToSlide(currentIndex - pushAmount);
        } else {
          sliderInstance.scrollToSlide(slideShowCount);
        }
      }, false);
    }
  })
}