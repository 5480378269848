// LTR requested it not do this...
// document.querySelectorAll(".contact-form").forEach(form => {
//   let referrer = document.referrer;
//   let refpath = getPath(referrer);
//   setField(form, "00N3l00000QKXyE", refpath);
// });

document.querySelectorAll(".contact-anchor-link").forEach(link => {
  let location = link.getAttribute("data-name");
  let value = `${location}`;
  let form = document.querySelector(".contact-form");
  link.addEventListener("click", event => {
    setField(form, "00N3l00000QKXyE", value);
  });
});

function setField(form, name, value) {
  if (!form || !name || !value) return;

  let field = form.querySelector(`input[name="${name}"]`);

  if (!field) {
    field = document.createElement("input");
    field.setAttribute("type", "hidden");
    form.appendChild(field);
  }

  field.setAttribute("name", name);
  field.setAttribute("value", value);
}

function getPath(ref) {
  let url;

  try {
    url = new URL(ref);
    return url.pathname;
  } catch (err) {
    console.log(err);
    return ref;
  }
}

// const mapping = [
//   {
//     key: "/Buy-Used-Tires/",
//     value: "Used Tires"
//   },
//   {
//     key: "/Collection-and-Processing/",
//     value: "Tire Collection and Processing"
//   },
//   {
//     key: "/Products/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/Products/Commercial-Products/AsphaltSmartMix/",
//     value: "Asphalt"
//   },
//   {
//     key: "/Products/Commercial-Products/Crumb/",
//     value: "Crumb"
//   },
//   {
//     key: "/Products/Commercial-Products/Commercial-Landscaping/",
//     value: "Landscaping"
//   },
//   {
//     key: "/Products/Commercial-Products/Commercial-Playgrounds/",
//     value: "Playground"
//   },
//   {
//     key: "/Products/Commercial-Products/TireDerived-Aggregate/",
//     value: "TDA"
//   },
//   {
//     key: "/Products/Commercial-Products/TireDerived-Fuel/",
//     value: "TDF"
//   },
//   {
//     key: "/Products/Commercial-Products/Wire/",
//     value: "Wire"
//   },
//   {
//     key: "/Products/Retail-Products/Organics/",
//     value: "Organics"
//   },
//   {
//     key: "/Products/Retail-Products/Retail-Landscaping-Products/",
//     value: "Landscaping"
//   },
//   {
//     key: "/Products/Retail-Products/Retail-Playground-Products/",
//     value: "Playground"
//   },
//   {
//     key: "/Applications/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/Applications/AgricultureEquine/",
//     value: "Equine"
//   },
//   {
//     key: "/Applications/Civil-Engineering/",
//     value: "Civil Engineering"
//   },
//   {
//     key: "/Applications/Construction/",
//     value: "Construction"
//   },
//   {
//     key: "/Applications/Energy-and-Fuel/",
//     value: "Energy and Fuel"
//   },
//   {
//     key: "/Applications/Infrastructure/",
//     value: "Infrastructure"
//   },
//   {
//     key: "/Applications/Landscaping/",
//     value: "Landscaping"
//   },
//   {
//     key: "/Applications/Playground/",
//     value: "Playground"
//   },
//   {
//     key: "/Applications/Sound-Control/",
//     value: "Sound Control"
//   },
//   {
//     key: "/Applications/Surfaces/",
//     value: "Surfaces"
//   },
//   {
//     key: "/Brands/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/Brands/AccessaMat/",
//     value: "Playground"
//   },
//   {
//     key: "/Brands/AquaBond/",
//     value: "Surfaces"
//   },
//   {
//     key: "/Brands/Burpee/",
//     value: "Organics"
//   },
//   {
//     key: "/Brands/DeckTop/",
//     value: "Surfaces"
//   },
//   {
//     key: "/Brands/EquiTile/",
//     value: "Equine"
//   },
//   {
//     key: "/Brands/Genaflex/",
//     value: "Surfaces"
//   },
//   {
//     key: "/Brands/Genesis/",
//     value: "Surfaces"
//   },
//   {
//     key: "/Brands/GroundSmart/",
//     value: "Landscaping"
//   },
//   {
//     key: "/Brands/Kid-Kushion/",
//     value: "Playground"
//   },
//   {
//     key: "/Brands/Lasting-Beauty/",
//     value: "Landscaping"
//   },
//   {
//     key: "/Brands/NuPlay/",
//     value: "Playground"
//   },
//   {
//     key: "/Brands/NuScape/",
//     value: "Landscaping"
//   },
//   {
//     key: "/Brands/Playsafer/",
//     value: "Playground"
//   },
//   {
//     key: "/Brands/RubberBond/",
//     value: "Surfaces"
//   },
//   {
//     key: "/Brands/Rubberific/",
//     value: "Rubberific"
//   },
//   {
//     key: "/Brands/SmartMIX/",
//     value: "Asphalt"
//   },
//   {
//     key: "/Brands/SportPlay/",
//     value: "Surfaces"
//   },
//   {
//     key: "/Sustainability/",
//     value: "Sustainability"
//   },
//   {
//     key: "/Sustainability/Advancing-Sustainable-Products/",
//     value: "Sustainability"
//   },
//   {
//     key: "/Sustainability/Accelerating-Environmental-Stewardship/",
//     value: "Sustainability"
//   },
//   {
//     key: "/Sustainability/Engaging-People-and-Communities/",
//     value: "Sustainability"
//   },
//   {
//     key: "/Sustainability/Operating-Responsibly/",
//     value: "Sustainability"
//   },
//   {
//     key: "/Resources/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/About/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/About/Mission-Vision-and-Values/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/About/Leadership/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/About/Innovation/",
//     value: "General Inquiry"
//   },
//   {
//     key: "/About/Careers/",
//     value: "General Inquiry"
//   }
// ];
