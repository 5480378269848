import cssHasPseudo from "../../node_modules/css-has-pseudo/dist/browser.mjs";

import "./imports.js";
import "./modal.js";
import "./utils.js";
import "./animations.js";

cssHasPseudo(document);

// Updates form filters with query params.
document.querySelectorAll(".filters").forEach(filterForm => {
  filterForm.elements.forEach(element => {
    const { name, value, checked } = element;
    if (!name) return;
    const param = window.params[name];
    if (!param) return;

    // Radio inputs
    if (param === value && typeof checked === "boolean") {
      element.checked = true;
      element.dispatchEvent(new Event("change"));
    }
  });
});
