const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

class FilterList {
  constructor(component, options = {}) {
    const filterControls = $$(".filter-control fieldset", component);
    const filterButtons = $$(".filter-control > button", component);
    const searchControl = $(".filter-search");
    const loadMoreSection = $(".load-more", component.parentNode);
    const items = $$("div[itemprop='itemListElement']", component.parentNode);
    const filters = $$(".filter-control input[type='radio']", component);
    const displayAmount = 8;
    let filterSelectors = [];

    filterControls.forEach(fieldset => {
      //Sorts the filter options
      let items = fieldset.childNodes;
      let itemsArr = [];
      for (var i in items) {
        const item = items[i];
        // get rid of the whitespace text nodes and one without label
        if (item.nodeType == 1) {
          if (!!$("span", item)) {
            itemsArr.push(item);
          } else {
            item.remove();
          }
        }
      }

      // If there is only "All" option, remove the fieldset's container.
      if (itemsArr.length === 1) {
        fieldset.parentElement.remove();
        return;
      }

      filterSelectors.push(`.filter-control input[name='${fieldset.name}']:checked`);

      itemsArr.sort(function(a, b) {
        let aSpan = $("span", a);
        let bSpan = $("span", b);
        let aSpanText = aSpan.innerHTML.toLowerCase();
        let bSpanText = bSpan.innerHTML.toLowerCase();

        if(bSpanText === 'all') {
          return 1;
        } else if (aSpanText === 'all') {
          return -1;
        } else if (aSpanText == bSpanText) {
          return 0;
        } else if (aSpanText > bSpanText) {
          return 1;
        } else {
          return -1;
        }
      });

      for (let i = 0; i < itemsArr.length; ++i) {
        fieldset.appendChild(itemsArr[i]);
      }
    });

    this.displayAmount = displayAmount;
    this.searchControl = searchControl;
    this.filterSelectors = filterSelectors;
    this.loadMoreSection = loadMoreSection;
    this.items = items;
    this.filterControls = filterControls;
    this.filterButtons = filterButtons;
    this.revealItems = this.revealItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.openFilters = this.openFilters.bind(this);
    this.initLoadMore = this.initLoadMore.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.component = component;
    let filterItems = this.filterItems;

    filters.forEach(input => {
      input.addEventListener('change', () => {
        let control = input.parentNode.parentNode.parentNode;
        let controlButton = $("button", control);
        if (input.value === "on") {
          controlButton.textContent = controlButton.getAttribute("data-field");
        } else {
          controlButton.textContent = input.value;
        }

        this.filterItems();
      })
    })

    //Close filter on outer click
    document.addEventListener('click', function (event) {
      let closest = event.target.closest(".filter-control");
      let openedFilters = $$(".filter-control fieldset.block");
      if (closest) return;

      openedFilters.forEach(filter => {
        filter.classList.remove("block");
        filter.classList.add("hidden");
        filter.previousElementSibling.setAttribute("aria-expanded","false");
      })
    }, false);

    filterButtons.forEach((button,index) => {
      button.addEventListener("click", this.openFilters);
    })

    if (searchControl) {
      $("input", searchControl).addEventListener('keyup', function(event) {
        if (event.code === 'Enter') {
          event.preventDefault();
          filterItems();
        }
      });
      $("button", searchControl).addEventListener("click", this.filterItems);
    }

    if (loadMoreSection && items.length > displayAmount) {
      loadMoreSection.classList.remove("hidden");
      $("button", loadMoreSection).addEventListener("click", this.loadMore);
    }

    //Catch browsers retained radio button selection on back button hit
    setTimeout(() => { filterItems(); }, 200);
  }

  filterItems() {
    let items = this.items;

    items.forEach(item => {
      item.classList.remove("filtered");
      item.classList.remove("revealed");
    })

    this.filterSelectors.forEach(selector => {
      let value = $(selector, this.component).value;
      let name = $(selector, this.component).name;

      if (value !== 'on') {
        items.forEach(item => {
          let content = item.getAttribute(`data-${name}`);
          if (content.indexOf(value) === -1) {
            item.classList.add("filtered");
          }
        })
      }
    })

    if (this.searchControl) {
      let searchInput = $("input", this.searchControl).value.toLowerCase();

      if (searchInput !== '') {
        items.forEach(item => {
          let content = item.textContent.toLowerCase()
          if (content.indexOf(searchInput) === -1) {
            item.classList.add("filtered");
          }
        })
      }
    }

    if (this.loadMoreSection) {
      let remainingItems = $$("div[itemprop='itemListElement']:not(.filtered)", this.component.parentNode);
      remainingItems = Array.prototype.slice.call(remainingItems);
      this.revealItems(remainingItems);
    }
  }

  initLoadMore(targetedItems) {
    this.items.forEach(item => {
      item.classList.remove("hidden");
    })

    if (targetedItems.length > this.displayAmount) {

      targetedItems.forEach((item, index) => {
        if (index >= this.displayAmount) {
          item.classList.add("hidden");
        }
      })

      this.loadMoreSection.classList.remove("hidden");
    } else {
      this.loadMoreSection.classList.add("hidden");
    }
  }

  loadMore() {
    let hiddenNotFiltered = $$("div[itemprop='itemListElement']:not(.revealed):not(.filtered)", this.component.parentNode);
    let items = Array.prototype.slice.call(hiddenNotFiltered, 0, this.displayAmount);
    this.revealItems(items);
  }

  revealItems(items) {
    let slicedItems = items.slice(0, this.displayAmount);

    slicedItems.forEach(item => {
      item.classList.add("revealed");
    })

    if (items.length < this.displayAmount) {
      this.loadMoreSection.classList.add("hidden");
    } else {
      this.loadMoreSection.classList.remove("hidden");
    }
  }

  openFilters(event) {
    event.preventDefault();
    let button = event.target;
    let fieldset = button.nextElementSibling;;
    let openedFilters = $$(".filter-control fieldset.block");
    let opened = fieldset.classList.contains("block");

    openedFilters.forEach(filter => {
      filter.classList.remove("block");
      filter.classList.add("hidden");
      filter.previousElementSibling.setAttribute("aria-expanded","false");
    })

    if (!opened) {
      fieldset.classList.add("block");
      fieldset.classList.remove("hidden");
      button.setAttribute("aria-expanded","true");
    }
  }
}

const filterForms = document.querySelectorAll(".filters");
for (let i = 0, count = filterForms.length; i < count; i++) {
  new FilterList(filterForms[i], {

  });
}

export default FilterList;
